<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <input type="file" ref="upload" @change="upload_server" style="display:none;">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="events"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.SaleCost="{ item }">
                                <span style="font-size:1.3rem;">{{ item.SaleCost }}</span> <span style="text-decoration: line-through;">{{ item.Cost }}</span>
                            </template>
                            <template v-slot:item.Image="{ item }">
                                <img style="width:50px;height:50px;" :src="item.Image" />
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn small v-if="item.IsGhim == 1" @click="huy_dang(item)">
                                    Hủy Đăng
                                </v-btn>
                                <v-btn small v-if="item.IsGhim == 0" @click="dang(item)">
                                    Đăng
                                </v-btn>
                                <v-btn style="margin-left:10px;" small @click="confirm_delete(item)">
                                    <v-icon small >
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                    <div class="col-md-12">
                        <div style="display:flex;gap:10px;align-items: center;">
                            <v-text-field
                                    v-model="code"
                                    label="CODE"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="name"
                                    label="Tên sản phẩm"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="cost"
                                    label="Giá gốc"
                                    type="number"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="sale_cost"
                                    label="Giá sale"
                                    type="number"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="description"
                                    label="Mô tả"
                                ></v-text-field>
                                <template v-if="image.length == 0">
                                    <v-btn color="info" @click="browse_file"><v-icon class="mr-2">mdi-cloud-upload</v-icon>Tải ảnh lên...</v-btn>
                                </template>
                                <template v-if="image.length > 0">
                                    <img :src="image" style="width:50px;height:50px">
                                </template>
                        </div>
                        <div style="display: flex;justify-content: flex-end;">
                            <div>
                                    <v-btn small @click="create_event">
                                        Submit
                                    </v-btn>
                                    <v-btn style="margin-left:10px;" small @click="confirm_delete_all()">
                                        Clear All
                                    </v-btn>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Live Events'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: '',
            code: '',
            name: '',
            cost: 0,
            sale_cost: 0,
            image: '',
            description: '',
            headers: [
                { text: "Code", align: 'start', sortable: false, value: 'Code'},
                { text: "Hình ảnh", value: 'Image', sortable: false },
                { text: "Tên S/P", value: 'Name', sortable: false },
                { text: "Giá", value: 'SaleCost', sortable: false },
                { text: "Mô tả", value: 'Description', sortable: false },
                { text: "Gim", value: 'Is_active', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
            ],
        };
    },
    watch: {

    },
    computed: {
        events() {
            let events = this.$store.getters['events/get_events'];
            events.sort(function(a, b) {
                return b.IsGhim - a.IsGhim;
            });
            return events;
        },
    },
    components: {
        
    },
    methods: {
        get_events() {
            var self    = this;
            self.loading = true;

            this.$router.push({
                name: "events-index",
                query: { }
            }).catch(() => { });

            this.$store.dispatch('events/get_all', {}).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        confirm_delete(item) {
            var self = this;
            Swal.fire({
                title: `Xoá: #${item.Code}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Code);
                }
            })
        },
        confirm_delete_all() {
            var self = this;
            Swal.fire({
                title: `Xóa tất cả?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item("all");
                }
            })
        },
        delete_item(code) {
            this.$store.dispatch('events/delete_event', { id: code, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_events();
            }).catch(() => {
                this.loading = false;
            });
        },
        
        update_active(item) {
            console.log("====> active", item)
            this.loading = true;

            var payload = {
                Id: item.Id,
                Event_live: item.Event_live,
                Id_item_gim: item.Id_item_gim ? item.Id_item_gim.Id : item.Id_item_gim,
                List_id_item_live: item.List_id_item_live ? item.List_id_item_live.map(e => e.Id).join(",") : "",
                Description_event: item.Description_event,
                Is_active: item.Is_active == true ? 1 : 0,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('events/update_event', payload).then(() => {
                this.loading = false;
                this.get_events();
            }).catch(() => {
                this.loading = false;
            });
        },
        browse_file() {
            this.$refs.upload.click();
        },
        async upload_server() {
            // var self = this;
            var file = this.$refs.upload.files[0];
            if(file) {
                var url = await this.upload_one(file);
                this.image = url;
            }
        },
        async upload_one(file) {
            var res = await this.$store.dispatch('uploads/upload', {file: file});
            return res.data.url;
        },

        create_event() {
            this.loading = true;
            var payload = {
                Code: this.code,
                Cost: this.cost ? parseInt(this.cost) : 0,
                Description: this.description,
                Name: this.name,
                Image: this.image,
                Status: 1,
                IsGhim: 0,
                SaleCost: this.sale_cost ? parseInt(this.sale_cost) : 0,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('events/create_event', payload).then(() => {
                this.loading = false;
                this.get_events();
                // this.$router.push({ name: 'events-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        dang(item) {
            this.loading = true;
            var payload = {
                Code: item.Code,
                Cost: item.Cost ? parseInt(item.Cost) : 0,
                Description: item.Description,
                Name: item.Name,
                Image: item.Image,
                Status: 1,
                IsGhim: 1,
                SaleCost: item.SaleCost ? parseInt(item.SaleCost) : 0,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('events/create_event', payload).then(() => {
                this.loading = false;
                this.get_events();
                // this.$router.push({ name: 'events-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        huy_dang(item) {
            this.loading = true;
            var payload = {
                Code: item.Code,
                Cost: item.Cost ? parseInt(item.Cost) : 0,
                Description: item.Description,
                Name: item.Name,
                Image: item.Image,
                Status: 1,
                IsGhim: 0,
                SaleCost: item.SaleCost ? parseInt(item.SaleCost) : 0,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('events/create_event', payload).then(() => {
                this.loading = false;
                this.get_events();
                // this.$router.push({ name: 'events-index' });
            }).catch(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Live Events", route: "index" }, { title: "Tất cả" } ]);
        this.get_events();
    }
};
</script>
